const cabinImages = [
    "/images/molenillo/molenillo01.jpg",
    "/images/molenillo/molenillo02.jpg",
    "/images/molenillo/molenillo03.jpg",
    "/images/molenillo/molenillo04.jpg",
    "/images/molenillo/molenillo05.jpg",
    "/images/molenillo/molenillo06.jpg",
    "/images/molenillo/molenillo07.jpg",
    "/images/molenillo/molenillo08.jpg",
    "/images/molenillo/molenillo09.jpg",
    "/images/molenillo/molenillo10.jpg",
    "/images/molenillo/molenillo11.jpg",
    "/images/molenillo/molenillo12.jpg",
    "/images/molenillo/molenillo13.jpg",
    "/images/molenillo/molenillo14.jpg",
    "/images/molenillo/molenillo15.jpg",
    "/images/molenillo/molenillo16.jpg",
    "/images/molenillo/molenillo17.jpg"
]

export default cabinImages