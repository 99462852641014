import Cabin from "../components/cabin/Cabin"
import { useTranslation } from 'react-i18next';
import cabinImages from "../mock/molenilloImages";

const HouseMolenillo = () => {
    const { t } = useTranslation();
    const cabin = t('molenillo');
    return (
        <Cabin data={{ ...cabin, cabinImages }} />
    )
}

export default HouseMolenillo