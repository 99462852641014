const cabinImages = [
    "/images/pitahaya/pitahaya01.jpg",
    "/images/pitahaya/pitahaya02.jpg",
    "/images/pitahaya/pitahaya03.jpg",
    "/images/pitahaya/pitahaya04.jpg",
    "/images/pitahaya/pitahaya05.jpg",
    "/images/pitahaya/pitahaya06.jpg",
    "/images/pitahaya/pitahaya07.jpg",
    "/images/pitahaya/pitahaya08.jpg",
    "/images/pitahaya/pitahaya09.jpg",
    "/images/pitahaya/pitahaya10.jpg",
    "/images/pitahaya/pitahaya11.jpg",
    "/images/pitahaya/pitahaya12.jpg",
    "/images/pitahaya/pitahaya13.jpg",
    "/images/pitahaya/pitahaya14.jpg",
    "/images/pitahaya/pitahaya15.jpg",
    "/images/pitahaya/pitahaya16.jpg",
    "/images/pitahaya/pitahaya17.jpg",
    "/images/pitahaya/pitahaya18.jpg",
    "/images/pitahaya/pitahaya19.jpg",
]

export default cabinImages