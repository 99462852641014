import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toggleDarkMode, selectDarkMode } from '../../redux/slices/darkModeSlice';
import Form from '../calendar/Form';
import ErrorModal from '../calendar/ErrorModal';
import { useTranslation } from 'react-i18next';
import ChangeLanguage from './ChangeLanguaje';

const Header = () => {
    const { t } = useTranslation();
    const navigation = t('navigation');
    return (
        <>
            {/* <a href="https://fareharbor.com/embeds/book/losjobostamarindo/?full-items=yes" class="fh-button-2d fh-fixed--side">Book Tours And Tranfers Now</a> */}
            <div className='position-absolute w-100' style={{ zIndex: "100" }}>
                <nav className="navbar navbar-expand-lg">
                    <div className="container">
                        <Link className="navbar-brand" to="/">
                            <img className='header-logo' src="/images/los-jobos-logo.png" alt="los jobos logo" />
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="modal" data-bs-target="#headerModal">
                            <i className="fa-solid fa-bars text-white fs-2"></i>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarNavDropdown">
                            <ul className="navbar-nav mx-auto">
                                <li className="nav-item mx-4">
                                    <CustomLink to='/'>{navigation[0]}</CustomLink>
                                </li>
                                <li className="nav-item mx-4">
                                    <CustomLink to="/molenillo">Cabaña Molenillo</CustomLink>
                                </li>
                                <li className="nav-item mx-4">
                                    <CustomLink to="/pitahaya">Cabaña Pitahaya</CustomLink>
                                </li>
                                <li className="nav-item mx-4">
                                    <CustomLink to="/about-us">{navigation[1]}</CustomLink>
                                </li>
                                <div className="nav-item mx-4">
                                    <a className='text-decoration-none' href="https://fareharbor.com/embeds/book/losjobostamarindo/?full-items=yes" target="_blank" rel="noreferrer">
                                        <span className='nav-link text-white'>
                                            {navigation[2]}
                                        </span>
                                    </a>
                                </div>
                            </ul>
                            <div className='d-flex align-items-center ms-3 ms-xl-0'>
                                <a href="https://www.facebook.com/profile.php?id=100075976217103&mibextid=LQQJ4d" target="_blank" rel="noreferrer" className="text-decoration-none me-xl-4">
                                    <i className="fa-brands fa-facebook-square fa-2x text-white mx-2"></i>
                                </a>
                                <a href="https://www.instagram.com/cabanaslosjobos/" target="_blank" rel="noreferrer" className="text-decoration-none me-xl-5">
                                    <i className="fa-brands fa-instagram fa-2x text-white mx-2"></i>
                                </a>
                                <DarkModeSwitch />
                                <ChangeLanguage />
                            </div>
                        </div>
                    </div>
                </nav>
            </div>

            <div className="modal fade" id="headerModal" tabIndex="-1" aria-labelledby="headerModal">
                <div className="modal-dialog modal-dialog-centered modal-fullscreen">
                    <div className="modal-content bg-jobos-brown-dark border-0">
                        <div className="modal-body">
                            <div className='d-flex flex-column justify-content-between pt-4 h-100'>
                                <div className='d-flex flex-column align-items-center '>
                                    <Link className="navbar-brand" to="/">
                                        <img className='header-logo-mobile' src="/images/los-jobos-logo.png" alt="los jobos logo" data-bs-toggle="modal" data-bs-target="#headerModal" />
                                    </Link>
                                    <div className='d-flex mt-5'>
                                        <a href="https://www.facebook.com/profile.php?id=100075976217103&mibextid=LQQJ4d" target="_blank" rel="noreferrer" className="text-decoration-none">
                                            <i className="fa-brands fa-facebook-square fa-2x text-white mx-3" data-bs-toggle="modal" data-bs-target="#headerModal"></i>
                                        </a>
                                        <a href="https://www.instagram.com/cabanaslosjobos/" target="_blank" rel="noreferrer" className="text-decoration-none">
                                            <i className="fa-brands fa-instagram fa-2x text-white mx-3" data-bs-toggle="modal" data-bs-target="#headerModal"></i>
                                        </a>
                                    </div>
                                    <div className="nav-item mt-4">
                                        <CustomLinkMobile to='/'>{navigation[0]}</CustomLinkMobile>
                                    </div>
                                    <div className="nav-item mt-4">
                                        <CustomLinkMobile to="/molenillo">Cabaña Molenillo</CustomLinkMobile>
                                    </div>
                                    <div className="nav-item mt-4">
                                        <CustomLinkMobile to="/pitahaya">Cabaña Pitahaya</CustomLinkMobile>
                                    </div>
                                    <div className="nav-item mt-4">
                                        <CustomLinkMobile to="/about-us">{navigation[1]}</CustomLinkMobile>
                                    </div>
                                    <div className="nav-item my-4">
                                        <a className='text-decoration-none' href="https://fareharbor.com/embeds/book/losjobostamarindo/?full-items=yes" target="_blank" rel="noreferrer">
                                            <span className='nav-link text-white'>
                                                {navigation[2]}
                                            </span>
                                        </a>
                                    </div>
                                    <DarkModeSwitch />
                                    <ChangeLanguage />
                                </div>
                                <button type="button" className="close-btn" data-bs-toggle="modal" data-bs-target="#headerModal">
                                    <i className="fa-solid fa-xmark fs-1"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Form />
            <ErrorModal />
        </>
    )
}

const CustomLinkMobile = ({ children, to }) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });
    return (
        <div>
            {match ? <ActiveNavLinkMobile to={to}>{children}</ActiveNavLinkMobile> : <NavLinkMobile to={to}>{children}</NavLinkMobile>}
        </div>
    );
}

const NavLinkMobile = ({ children, to }) => {
    return (
        <Link to={to} className='nav-link'>
            <span className='text-white' data-bs-toggle="modal" data-bs-target="#headerModal">{children}</span>
        </Link>
    )
}

const ActiveNavLinkMobile = ({ children, to }) => {
    return (
        <Link to={to} className='nav-link'>
            <span className='text-jobos-brown' data-bs-toggle="modal" data-bs-target="#headerModal">{children}</span>
        </Link>
    )
}

const CustomLink = ({ children, to }) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });
    return (
        <div>
            {match ? <ActiveNavLink to={to}>{children}</ActiveNavLink> : <NavLink to={to}>{children}</NavLink>}
        </div>
    );
}

const NavLink = ({ children, to }) => {
    return (
        <Link to={to} className='nav-link'>
            <span className='text-white'>{children}</span>
        </Link>
    )
}

const ActiveNavLink = ({ children, to }) => {
    return (
        <Link to={to} className='nav-link'>
            <span className='text-jobos-brown'>{children}</span>
        </Link>
    )
}

const DarkModeSwitch = () => {
    const dispatch = useDispatch();
    const darkMode = useSelector(selectDarkMode);

    const toggleDarkModeFunc = () => {
        dispatch(toggleDarkMode());
        if (darkMode) {
            document.body.classList.remove('dark-mode');
        } else {
            document.body.classList.add('dark-mode');
        }
    }
    return (
        <div className='d-flex align-items-center'>
            <label className="switch">
                <input type="checkbox" checked={darkMode} onChange={() => toggleDarkModeFunc()} />
                <i className={`fa-solid ${darkMode ? "fa-moon text-jobos-black" : "fa-sun text-jobos-brown"} fs-6 slider round`}></i>
            </label>
        </div>
    )
}

export default Header