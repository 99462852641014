import { Link } from "react-router-dom"
import { useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux';
import { fetchHouses, selectHouses } from "../../redux/slices/housesSlice";
import { selectDarkMode } from "../../redux/slices/darkModeSlice";
import { Trans } from 'react-i18next';

const OurStory = ({ history }) => {

    const { title } = history;

    const dispatch = useDispatch();
    const houses = useSelector(selectHouses);
    const darkMode = useSelector(selectDarkMode);
    useEffect(() => {
        dispatch(fetchHouses())
    }, [dispatch])

    return (
        <div className="py-5 my-xl-5">
            <div className="container">
                <div className="row justify-content-xl-center ">
                    <div className="col col-12 col-xl-5">
                        <h2 className="fs-45 fw-bold mb-3 text-jobos-brown">{title}</h2>
                        <div className={`fs-16 ls-2 lh-46 ${darkMode ? "text-white" : ""}`}>
                            <Trans i18nKey={"about.history.description"} />
                            <Trans i18nKey={"about.history.description1"} />
                        </div>
                        <div className="d-flex flex-column flex-md-row justify-content-center">
                            {
                                houses.length > 0 ?
                                    <>
                                        <Link to={'/pitahaya'} className="about-link jobos-btn p-2 text-decoration-none mb-3 me-md-3">{houses[0].name}</Link>
                                        <Link to={'/molenillo'} className="about-link jobos-btn p-2 text-decoration-none">{houses[1].name}</Link>
                                    </> : <></>
                            }
                        </div>
                    </div>
                    <div className="col col-12 col-xl-5">
                        <div className="mt-5 mt-xl-0 d-none d-xl-flex flex-row justify-content-center justify-content-xl-end">
                            <img className="img-fluid" src="/images/about-us/our-story1.jpg" alt="our story" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurStory