import Cabin from "../components/cabin/Cabin"
import { useTranslation } from 'react-i18next';
import cabinImages from "../mock/pitahayaImages";

const HousePitahaya = () => {
    const { t } = useTranslation();
    const cabin = t('pitahaya');
    return (
        <Cabin data={{ ...cabin, cabinImages }} />
    )
}

export default HousePitahaya