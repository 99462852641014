
const HeroMobile = ({ data }) => {
    return (
        <div id="caruselCabinMobile" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                {
                    data.map((item, index) => {
                        return (
                            <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <img className="cabin-carousel-img-mobile" key={index} src={`${item}`} alt={item} />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="jobos-carousel-indicators-mobile carousel-indicators">
                {
                    data.map((item, index) => {
                        return (
                            <button key={index} type="button" data-bs-target="#caruselCabinMobile" data-bs-slide-to={index} className={`${index === 0 ? 'active' : ''}`} aria-current="true" aria-label={`Slide ${index + 1}`}></button>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default HeroMobile