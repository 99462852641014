const Hero = ({ data }) => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col col-12 col-xl-9">
                        <div className="d-flex justify-content-center justify-content-xl-end">
                            <img type="button" className="cabin-main-img" src={data[0]} alt="" data-bs-toggle="modal" data-bs-target="#cabinModal" />
                        </div>
                    </div>
                    <div className="col col-12 col-xl-3">
                        <div className="d-none d-xl-flex h-100 flex-column justify-content-between">
                            <img type="button" className="cabin-side-img" src={data[1]} alt="" data-bs-toggle="modal" data-bs-target="#cabinModal" />
                            <img type="button" className="cabin-side-img" src={data[2]} alt="" data-bs-toggle="modal" data-bs-target="#cabinModal" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade bg-black-transparent" id="cabinModal" tabIndex="-1" aria-labelledby="cabinModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-fullscreen">
                    <div className="modal-content bg-transparent border-0 h-100">
                        <div className="modal-body py-0 px-5 mx-5 d-flex flex-column justify-content-center">
                            <div className="d-flex w-100 justify-content-end">
                                <button type="button" className="close-btn" data-bs-dismiss="modal" aria-label="Close">
                                    <i className="fa-solid fa-xmark"></i>
                                </button>
                            </div>
                            <Carousel data={data} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero

const Carousel = ({ data }) => {
    return (
        <div id="caruselCabin" className="carousel slide" data-bs-ride="carousel">
            <div className="row g-0">
                <div className="col">
                    <div className='d-none d-xl-flex align-items-center justify-content-center h-100'>
                        <button className="circle-btn bg-jobos-green" type="button" data-bs-target="#caruselCabin" data-bs-slide="prev">
                            <i className="fa-solid fa-chevron-left text-white fs-6"></i>
                        </button>
                    </div>
                </div>
                <div className="col-12 col-xl-10">
                    <div className="carousel-inner">
                        {
                            data.map((item, index) => {
                                return (
                                    <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <img className="cabin-carousel-img" key={index} src={`${item}`} alt={item} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className="jobos-carousel-indicators carousel-indicators">
                        {
                            data.map((item, index) => {
                                return (
                                    <button key={index} type="button" data-bs-target="#caruselCabin" data-bs-slide-to={index} className={`${index === 0 ? 'active' : ''}`} aria-current="true" aria-label={`Slide ${index + 1}`}>
                                        <img className="" src={item} alt={item} />
                                    </button>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="col">
                    <div className='d-none d-xl-flex align-items-center justify-content-center h-100'>
                        <button className="circle-btn bg-jobos-green" type="button" data-bs-target="#caruselCabin" data-bs-slide="next">
                            <i className="fa-solid fa-chevron-right text-white fs-6"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}