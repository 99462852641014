export const heroImages = [
    "/images/hero/hero01.jpg",
    "/images/hero/hero02.jpg",
    "/images/hero/hero03.jpg",
    "/images/hero/hero04.jpg",
    "/images/hero/hero05.jpg",
    "/images/hero/hero06.jpg",
    "/images/hero/hero07.jpg",
    "/images/hero/hero08.jpg",
    "/images/hero/hero09.jpg",
    "/images/hero/hero10.jpg",
]