import React from 'react'
import List from '../../components/cabin/List'
import { useTranslation, Trans } from 'react-i18next';

const Accordion = ({ data }) => {
    const { description, main, shared, amenities, services } = data
    const { t } = useTranslation();
    const cabin = t('cabin')
    return (
        <div className="accordion cabin-accordion" id="cabin-accordion">
            <div className="accordion-item cabin-accordion-item">
                <h2 className="accordion-header cabin-accordion-header">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-one" aria-expanded="true" aria-controls="collapse-one">
                        {cabin.description}
                    </button>
                </h2>
                <div id="collapse-one" className="accordion-collapse collapse show" data-bs-parent="#cabin-accordion">
                    <div className="accordion-body">
                        <p className='px-lg-5 pb-4'>
                            <Trans i18nKey={"description"}>{description}</Trans>
                        </p>
                    </div>
                </div>
            </div>
            <div className="accordion-item cabin-accordion-item">
                <h2 className="accordion-header cabin-accordion-header">
                    <button className="accordion-button accordion-border-top collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-two" aria-expanded="false" aria-controls="collapse-two">
                        {cabin.amenities}
                    </button>
                </h2>
                <div id="collapse-two" className="accordion-collapse collapse" data-bs-parent="#cabin-accordion">
                    <div className="accordion-body">
                        {
                            amenities.length > 0 ?
                                <List data={amenities} /> :
                                <></>
                        }
                    </div>
                </div>
            </div>
            <div className="accordion-item cabin-accordion-item">
                <h2 className="accordion-header cabin-accordion-header">
                    <button className="accordion-button accordion-border-top collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-three" aria-expanded="false" aria-controls="collapse-three">
                        {cabin.main}
                    </button>
                </h2>
                <div id="collapse-three" className="accordion-collapse collapse" data-bs-parent="#cabin-accordion">
                    <div className="accordion-body">
                        {
                            main.length > 0 ?
                                <List data={main} /> :
                                <></>
                        }
                    </div>
                </div>
            </div>
            <div className="accordion-item cabin-accordion-item">
                <h2 className="accordion-header cabin-accordion-header">
                    <button className="accordion-button accordion-border-top collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-four" aria-expanded="false" aria-controls="collapse-four">
                        {cabin.shared}
                    </button>
                </h2>
                <div id="collapse-four" className="accordion-collapse collapse" data-bs-parent="#cabin-accordion">
                    <div className="accordion-body">
                        {
                            shared.length > 0 ?
                                <List data={shared} /> :
                                <></>
                        }
                    </div>
                </div>
            </div>
            <div className="accordion-item cabin-accordion-item">
                <h2 className="accordion-header cabin-accordion-header">
                    <button className="accordion-button accordion-border-top collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-five" aria-expanded="false" aria-controls="collapse-five">
                        {cabin.services}
                    </button>
                </h2>
                <div id="collapse-five" className="accordion-collapse collapse" data-bs-parent="#cabin-accordion">
                    <div className="accordion-body">
                        {
                            services.length > 0 ?
                                <List data={services} /> :
                                <></>
                        }
                    </div>
                </div>
            </div>
            <div className="accordion-item cabin-accordion-item">
                <h2 className="accordion-header cabin-accordion-header">
                    <button className="accordion-button accordion-border-top collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-six" aria-expanded="false" aria-controls="collapse-six">
                        {cabin.reviews}
                    </button>
                </h2>
                <div id="collapse-six" className="accordion-collapse collapse" data-bs-parent="#cabin-accordion">
                    <div className="accordion-body">
                        <script src="https://static.elfsight.com/platform/platform.js" async></script>
                        <div className="elfsight-app-f1199eac-0495-470b-8cbe-31b9a9ae98e9" data-elfsight-app-lazy></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Accordion