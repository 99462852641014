import Features from "../components/home/Features"
import Hero from "../components/home/Hero"
import Presentation from "../components/home/Presentation"
import Video from "../components/home/Video"
import Houses from "../components/home/Houses"
import ActivitiesAround from "../components/home/ActivitiesAround"
import { useTranslation } from 'react-i18next';

const Home = () => {
    const { t } = useTranslation();
    const home = t('home');
    const molenillo = t('molenillo')
    const pitahaya = t('pitahaya')
    const houses = [molenillo, pitahaya]
    return (
        <div>
            <Hero {...home} />
            <Presentation {...home} />
            <Features />
            <Video />
            <Houses {...home} houses={houses} />
            <ActivitiesAround />
        </div>
    )
}

export default Home