import { Trans } from 'react-i18next';

const List = ({ data }) => {
    return (
        <div className="px-4 pb-4">
            {
                data.map((text, index) => {
                    return (
                        <div className="row g-1 mb-3" key={index}>
                            <div className="col-1">
                                <div className="d-flex align-items-center justify-content-end w-100 h-100">
                                    <i className="fas fa-check-circle text-jobos-green fs-14"></i>
                                </div>
                            </div>
                            <div className="col">
                                <span className="fs-18" style={{ color: "#383423" }}>
                                    <Trans i18nKey={index}>
                                        {text}
                                    </Trans>
                                </span>
                            </div>
                        </div>
                    )
                }
                )
            }
        </div>
    )
}

export default List