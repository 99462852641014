import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectDarkMode } from '../../redux/slices/darkModeSlice';
// import DatePicker from '../calendar/DatePicker';
import { Trans } from 'react-i18next';

const Presentation = ({ presentation }) => {
    const { button, descriptionArray } = presentation;
    const darkMode = useSelector(selectDarkMode);
    return (
        <div className="my-xl-5 py-5">
            {/* <div className='d-flex d-xxl-none justify-content-center pb-5'>
                <DatePicker shadowIsActive={false} />
            </div> */}
            <div className="container px-4">
                <div className="row d-flex justify-content-center">
                    {/* <div className="col col-12 col-xl-6 order-xl-2">
                        <div className='w-100 d-flex justify-content-center justify-content-xl-end'>
                            <img src="/images/presentation/presentation.png" className="img-fluid" alt="presentation" />
                        </div>
                    </div> */}
                    <div className="col col-12 col-xl-8">
                        <p className="fs-45 lh-53 fw-normal text-jobos-brown">
                            <Trans i18nKey={"home.presentation.title"} />
                        </p>
                        <p className={`fs-16 ls-2 lh-46 ${darkMode ? "text-white" : ""}`}>{
                            <Trans>{descriptionArray[0]}</Trans>
                        }</p>
                        <div className='w-100 d-flex justify-content-center'>
                            <img src="/images/home/home-1.jpg" className="img-fluid img-presentation" alt="presentation" />
                        </div>
                        <br />
                        <br />
                        <p className={`fs-45 lh-53 fw-normal text-jobos-brown`}>{
                            <Trans>{descriptionArray[1]}</Trans>
                        }</p>
                        <p className={`fs-16 ls-2 lh-46 ${darkMode ? "text-white" : ""}`}>{
                            <Trans>{descriptionArray[2]}</Trans>
                        }</p>
                        <div className='w-100 d-flex justify-content-center'>
                            <img src="/images/home/home-2.jpg" className="img-fluid img-presentation" alt="presentation" />
                        </div>
                        <br />
                        <br />
                        <p className={`fs-45 lh-53 fw-normal text-jobos-brown`}>{
                            <Trans>{descriptionArray[3]}</Trans>
                        }</p>
                        <p className={`fs-16 ls-2 lh-46 ${darkMode ? "text-white" : ""}`}>{
                            <Trans>{descriptionArray[4]}</Trans>
                        }</p>
                        <div className='w-100 d-flex justify-content-center'>
                            <img src="/images/home/home-3.jpg" className="img-fluid img-presentation" alt="presentation" />
                        </div>
                        <br />
                        <br />
                        <p className={`fs-16 ls-2 lh-46 ${darkMode ? "text-white" : ""}`}>{
                            <Trans>{descriptionArray[5]}</Trans>
                        }</p>
                        {/* {
                            descriptionArray.map((element, index) => {
                                return (
                                    <p key={index} className={`fs-16 ls-2 lh-46 ${darkMode ? "text-white" : ""}`}>{
                                        <Trans>{element}</Trans>
                                    }</p>
                                )
                            })
                        } */}
                        <div className='w-100 d-flex justify-content-center'>
                            <Link to={"about-us"} className="jobos-btn p-3 mt-5 text-decoration-none">{button}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Presentation