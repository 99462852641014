import Hero from "./Hero"
import HeroMobile from "./HeroMobile"
// import DatePicker from "../calendar/DatePicker"
import Accordion from "./Accordion"
import Features from "../home/Features"
import ActivitiesAround from "../home/ActivitiesAround"
import ReservationModal from "./ReservationModal"

const Cabin = ({ data }) => {
    const { description, main, shared, amenities, services, name, cabinImages } = data

    return (
        <>
            <div className="cabin-header bg-jobos-brown-dark position-relative text-center text-white">
                <h1 className="position-absolute top-65 start-50 translate-middle text-center text-white fs-40 fw-medium">{name}</h1>
            </div>
            {
                cabinImages.length > 0 ?
                    <div>
                        <div className="py-5 d-none d-xl-block">
                            <Hero data={cabinImages} />
                        </div>
                        <div className="d-xl-none">
                            <HeroMobile data={cabinImages} />
                        </div>
                    </div>

                    : <div>loading...</div>
            }

            <div className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col col-12 col-xl-8 order-1 order-xl-0">
                            <Accordion data={{ description, main, shared, amenities, services }} />
                        </div>
                        <div className="col col-12 col-xl-4 order-0 order-xl-1">
                            <div className="d-flex justify-content-center mb-5 mt-xl-0">
                                {/* <DatePicker cabinId={id} cabinSelectorIsActive={false} /> */}
                                {/* <ReservationModal /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Features />
            <ActivitiesAround />
        </>
    )
}

export default Cabin