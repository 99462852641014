import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectDarkMode } from '../../redux/slices/darkModeSlice';
import DatePicker from '../calendar/DatePicker';
import { Trans } from 'react-i18next';

const Presentation = ({ presentation }) => {
    const { button, descriptionArray } = presentation;
    console.log("descriptionArray", descriptionArray)
    const darkMode = useSelector(selectDarkMode);
    return (
        <div className="my-xl-5 py-5">
            <div className='d-flex d-xxl-none justify-content-center pb-5'>
                <DatePicker shadowIsActive={false} />
            </div>
            <div className="container px-4">
                <div className="row g-5">
                    <div className="col col-12 col-xl-6 order-xl-2">
                        <div className='w-100 d-flex justify-content-center justify-content-xl-end'>
                            <img src="/images/presentation/presentation.png" className="img-fluid" alt="presentation" />
                        </div>
                    </div>
                    <div className="col col-12 col-xl-6 order-xl-1">
                        <p className="fs-45 lh-53 fw-normal text-jobos-brown">
                            <Trans i18nKey={"home.presentation.title"} />
                        </p>
                        {
                            descriptionArray.map((element, index) => {
                                return (
                                    <p key={index} className={`fs-14 ls-2 ${darkMode ? "text-white" : ""}`}>{
                                        <Trans>{element}</Trans>
                                    }</p>
                                )
                            })
                        }
                        <div className='w-100 d-flex justify-content-center justify-content-xl-start'>
                            <Link to={"about-us"} className="jobos-btn p-3 mt-5 text-decoration-none">{button}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Presentation